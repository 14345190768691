$nav-bg: #fff;
$nav-color: #003559;
$top-border: #b5d9f0;

$ql-bg: #003559;
$ql-click: #b5d9f0;

$btn1: #003559;
$btn-text-1: #fff;
$btn2: #b5d9f0;
$btn-text-2: #fff;
$btn3: #d5b979;
$btn-text-3: #fff;
$btn4: #707176;
$btn-text-4: #fff;

$vision-bg: #b5d9f0;
$vision-title-color: #003559;
$vision-body-color: #003559;

$stats-bg: #003559; 
$stats-text-color: #b5d9f0;
$stats-body-color: #fff;

$diary-bg: #d5b979;
$dd1: #b5d9f0;
$ddt1: #fff;
$dd2: #707176;
$ddt2: #fff;
$dd3: #003559;
$ddt3: #fff;
$dd4: #fff;
$ddt4: $diary-bg;

$footer-bg: #707176;
$trust-btn-bg: #003559;