// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4338 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px; 
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 700px;
    min-height: 700px;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 600px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 450px;
    min-height: 450px;
  }

  &:before {
    @include before(100%, 450px);
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  }

  &:after {
    @include before(100%, 450px);
    top: 0;
    left: 0;
    background: url(/i/design/amazing-gradient.png) repeat-x top left;
  }
}

.backstretch {
  position: relative;

  &:before {
    @include before(100%, 500px);
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(#000, 1) 0%, rgba(#000, 0) 100%);
  }

  img {
    top: 0px !important;
    animation: scale 25s ease forwards infinite;
  }

  @keyframes scale {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.25);
    }
  }
}

.body_4338 .backstretch {

  @media only screen and (min-width: 990px) {
    opacity: 0;
    animation: appear 2s ease forwards 1;

    @keyframes appear {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}

// -----------------------------------//
// BACKSTRETCH NAVIGATION
// -----------------------------------//

.backstretch-nav {
  width: 95%;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: 150px;
  z-index: 99;

  @media only screen and (max-width: 990px) {
    display: none;
  }

  #prevBS,
  #nextBS {
    @include box(40px);
    font-size: 20px;
    color: #fff;
    box-shadow: inset 0 0 0 2px rgba(#fff, 0);
    outline: 2px solid #fff;
    outline-offset: -1px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    transition: .3s;
    float: left;

    span {
      @include center;
      top: 45%;
      font-weight: 800;
    }

    &:hover {
      box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 20px rgba(#fff, .2);
    }
  }

  #nextBS {
    float: right;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  // width: 990px;
  max-width: 95%;
  text-align: center;
  z-index: 99;
  color: #fff;
  line-height: 1;
  font-size: 50px;

  @media only screen and (max-width: 1800px) {
    font-size: 45px;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 40px;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 35px;
  }

  @media only screen and (max-width: 990px) {
    // @include posreset;
    font-size: 30px;
  }

  @media only screen and (max-width: 500px) {
    font-size: 25px;
  }
}