// ----------------------------------- BTNS

#btns-sec {
 background: rgba($vision-bg, .9);
 position: relative;
 border-top: 10px solid #fff;

 &:before,
 &:after {
  @include before(80%, 100%);
  top: 0;
  right: 0;
  background: linear-gradient(to left, rgba($vision-bg, 1) 0%, rgba($vision-bg, 0) 100%);
 }

 .btns {
  text-align: center; 
  @extend .clearfix;
  position: relative;
  z-index: 1;
  transform: translateY(-100px);

  @media only screen and (max-width: 990px) {
   transform: translateY(-50px);
  }

  li {
   display: inline-block;
   padding: 0;
   background: none;
   position: relative;
   max-width: calc(100% / 4 - 20px);
   transition: .3s;
   overflow: hidden;
   margin: 0 5px;
   box-shadow: 0 0 0 2px #fff, -10px 10px 45px 0px rgba(0, 0, 0, 0.2);

   @media only screen and (max-width: 990px) {
    max-width: unset;
    margin: 10px;
    transform: none !important;
   }

   a {
    @include box(100%);
    display: block;
    transition: .3s;
    border-radius: inherit;
    overflow: hidden;
   }

   img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
    mix-blend-mode: luminosity;
    opacity: .3;
    transition: .3s;
   }

   span {
    @include center;
    top: 77%;
    color: #fff;
    width: 90%;
    z-index: 102;
    font-size: 1.7vw;
    line-height: 1.2;
    font-weight: 800;

    @media only screen and (max-width: 990px) {
     font-size: 22px;
    }
   }

   &:hover {
    img {
     transform: scale(1.1);
    }
   }

   &:nth-of-type(1) {
    background: $btn1;
    border-radius: 15px 50px 15px 100px;
    transform: translateY(-20px);

    span {
     color: $btn-text-1;
    }

    &:before,
    &:after {
     @include before(100%, 50%);
     bottom: 0;
     left: 0;
     background: linear-gradient(0deg, rgba($btn1, 1) 0%, rgba($btn1, 0) 100%);
     z-index: 101;
     opacity: .8;
     transition: .3s;
    }
   }

   &:nth-of-type(2) {
    background: $btn2;
    border-radius: 100px 15px 50px 15px;
    transform: translateY(20px);

    span {
     color: $btn-text-2;
    }

    &:before,
    &:after {
     @include before(100%, 50%);
     bottom: 0;
     left: 0;
     background: linear-gradient(0deg, rgba($btn2, 1) 0%, rgba($btn2, 0) 100%);
     z-index: 101;
     opacity: .8;
     transition: .3s;
    }
   }

   &:nth-of-type(3) {
    background: $btn3;
    border-radius: 15px 50px 15px 100px;
    transform: translateY(-20px);

    span {
     color: $btn-text-3;
    }

    &:before,
    &:after {
     @include before(100%, 50%);
     bottom: 0;
     left: 0;
     background: linear-gradient(0deg, rgba($btn3, 1) 0%, rgba($btn3, 0) 100%);
     z-index: 101;
     opacity: .8;
     transition: .3s;
    }
   }

   &:nth-of-type(4) {
    background: $btn4;
    border-radius: 100px 15px 50px 15px;
    transform: translateY(20px);

    span {
     color: $btn-text-4;
    }

    &:before,
    &:after {
     @include before(100%, 50%);
     bottom: 0;
     left: 0;
     background: linear-gradient(0deg, rgba($btn4, 1) 0%, rgba($btn4, 0) 100%);
     z-index: 101;
     opacity: .8;
     transition: .3s;
    }
   }
  }

  @media only screen and (min-width: 990px) {

   &:hover li:not(:hover) {
    transform: scale(.95);
    opacity: .3;
   }
  }
 }
}