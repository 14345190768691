$inner-text-size: 55px;
$tex-fill: $stats-text-color;

#animated-stats {
  padding: 100px 0 200px;
  background: $stats-bg;
  text-align: center;
  border-top: 10px solid #fff;

  @media only screen and (max-width: 1800px) {
    padding: 90px 0 180px;
  }

  @media only screen and (max-width: 1500px) {
    padding: 80px 0 160px;
  }

  @media only screen and (max-width: 1350px) {
    padding: 70px 0 140px;
  }

  @media only screen and (max-width: 990px) {
    padding: 60px 5% 80px;
  }

  h2 {
    margin: 0 auto 80px;
    font-size: 30px;
    color: $stats-body-color;
    line-height: 1.2;
    letter-spacing: 10px;
    font-weight: 500;

    @media only screen and (max-width: 1500px) {
      font-size: 25px;
      margin: 0 auto 50px;
    }

    @media only screen and (max-width: 990px) {
      letter-spacing: 0;
      font-size: 20px;
      margin: 0 auto;
    }

    span {
      display: block;
      font-size: 50px;
      letter-spacing: 0;

      @media only screen and (max-width: 1500px) {
        font-size: 40px;
      }

      @media only screen and (max-width: 990px) {
        font-size: 30px;
      }
    }
  }
}

#performance-group {
  width: 100%;
  font-size: 0; // hides physical integer

  #performance,
  #performance2,
  #performance3 {
    border-radius: 50%;
    outline: 30px solid rgba(#fff, .2);
    outline-offset: -30px;
  }

  text {
    fill: $tex-fill;
    font-size: $inner-text-size;
    font-weight: 800;
  }

  .donut-item {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    vertical-align: top;

    @media only screen and (max-width: 1500px) {
      transform: scale(.9);
    }

    @media only screen and (max-width: 1300px) {
      transform: scale(.8);
    }

    &:not(:first-of-type),
    &:not(:last-of-type) {
      margin: 0 40px;

      @media only screen and (max-width: 1500px) {
        margin: 0 30px;
      }

      @media only screen and (max-width: 1300px) {
        margin: 0;
      }

      @media only screen and (max-width: 990px) {
        margin: 30px;
      }
    }

    // for decimal case

    // &:nth-of-type(1),
    // &:nth-of-type(3) {
    //   text {
    //     fill: none;
    //   }

    //   #decimal {
    //     position: absolute;
    //     top: 105px;
    //     display: block;
    //     width: 100%;
    //     color: $tex-fill;
    //     z-index: 999;
    //     font-size: $inner-text-size;
    //     // background: red;
    //   }
    // }

    p {
      font-size: 24px;
      color: $stats-body-color;
      margin: 40px auto 0;

      span {
        display: block;
        text-transform: none;
        margin: 10px auto 0;
        font-size: 80%;
      }
    }
  }

  path.color0 {
    fill: #fff;
  }

  path.color1 {
    fill: transparent;
  }
}

// ------- C_IMPORT

// [data-donut],
// #performance-group .donut-item:nth-of-type(3) #decimal {
// 	background: #222;
// 	color: #fff;
// 	border: 3px dotted #fff;
// 	padding: 10px;
// 	font-size: 50px;
// }

// #performance-group .donut-item:nth-of-type(3) #decimal {
// 	top: 0;
// }

// #performance-group .donut-item p.desc-stat {
// 	background: #444;
// 	color: #fff;
// 	border: 2px dotted #fff;
// 	padding: 10px;
// }