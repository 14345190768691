// -----------------------------------//
// DIARY
// -----------------------------------//

#diary-sec {
 padding: 250px 0 200px;
 background: rgba($diary-bg, .9);
 position: relative; 
 overflow: hidden;
 border-top: 10px solid #fff;

 @media only screen and (max-width: 990px) {
  padding: 80px 0;
 }

 &:before {
  @include before(95%, 92%);
  position: absolute;
  left: 0; 
  right: 0;
  margin: 0 auto;
  top: 0;
  box-shadow: 0px 0px 174px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 250px 0px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(#fff, .2);

  @media only screen and (max-width: 990px) {
   display: none;
  }
 }

 &:after {
  @include before(51px, 71px);
  background: url(/i/design/diary-logo-small.png) no-repeat center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
  mix-blend-mode: luminosity;

  @media only screen and (max-width: 990px) {
   display: none;
  }
 }

 .wrapper {
  width: 95%;
 }

 h2 {
  position: absolute;
  top: -180px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 13vw;
  text-align: center;
  line-height: 1;
  -webkit-text-stroke: 2px #fff;
  text-stroke: 2px #fff;
  color: transparent;
  text-transform: uppercase;
  font-weight: 800;

  @media only screen and (max-width: 990px) {
   @include posreset;
   font-size: 50px;
   margin: 0 auto 50px;
  }
 }
}

//Diary
#SideHeadingDiary {

 ul.SideList {
  text-align: center;
  padding: 0 0 0 5%;

  @media only screen and (max-width: 990px) {
   padding: 0 5%;
  }

  li {
   display: inline-block;
   padding: 0;
   background: none;
   position: relative;
   transition: .3s;
   text-align: left;
   margin: 0 0 30px 0;
   width: 40%;

   @media only screen and (max-width: 990px) {
    width: auto;
    margin: 20px !important;
    text-align: center;
   }

   a {
    color: #000;
   }

   p.date {
    @include box(13vw);
    border-radius: 50%;
    position: relative;
    line-height: .9;
    font-size: 2vw;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 800;

    @media only screen and (max-width: 990px) {
     @include box(150px);
     font-size: 25px;
    }

    @media only screen and (max-width: 500px) {
     display: block;
     margin: 0 auto 10px;
    }

    .inner {
     @include center;
     top: 48%;

     span {
      display: block;
      font-size: 150%;
     }
    }
   }

   p.title {
    display: inline-block;
    vertical-align: middle;
    width: 45%;
    color: #fff;
    margin: 0 0 0 20px;

    @media only screen and (max-width: 500px) {
     display: block;
     width: auto;
     margin: 0 auto;
    }

    // Truncate
    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     height: 50px;
    }
   }

   &:nth-of-type(1) {

    p.date {
     background: $dd1;
     color: $ddt1;
    }
   }

   &:nth-of-type(2) {
    margin: 0 10% 30px 0;

    p.date {
     background: $dd2;
     color: $ddt2;
    }
   }

   &:nth-of-type(3) {
    margin: 0 0 30px 19%;

    p.date {
     background: $dd3;
     color: $ddt3;
    }
   }

   &:nth-of-type(4) {
    p.date {
     background: $dd4;
     color: $ddt4;
    }
   }
  }

  @media only screen and (min-width: 990px) {

   &:hover li:not(:hover) {
    transform: scale(.9);
    opacity: .3;
   }
  }
 }

 .btn-wrap {
  margin: 50px auto 0;
  text-align: center;

  a {
   display: inline-block;
   padding: 25px 0;
   width: 290px;
   border-radius: 50px;
   box-shadow: inset 0 0 0 2px #fff;
   color: #fff;
   margin: 0 15px;
   font-weight: 800;
   font-size: 20px;
   transition: .3s;

   @media only screen and (max-width: 1500px) {
    width: 250px;
    padding: 20px 0;
    margin: 0 10px;
   }

   @media only screen and (max-width: 990px) {
    width: 220px;
    padding: 15px 0;
    margin: 5px;
    font-size: 16px;
   }

   &:hover {
    box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 40px rgba(#fff, .1);
    letter-spacing: 1px;
   }
  }
 }
}