.bx-cont {
  width: 40%;
  padding: 0 0 0 5%;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  transform: translateY(-50px);

  @media only screen and (max-width: 990px) {
    width: auto;
    padding: 0;
    display: block;
    transform: none;
    margin: 0 0 50px 0;
  }

  &:before {
    @include before(249px, 88px);
    background: url(/i/design/quotes-ic.png) no-repeat center;
    right: 0;
    bottom: -90px;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  ul#bxslider {

    .bx-viewport {
      width: 100%;
    }
 
    li {
      padding: 0;
      background: none;
      text-align: left;
      width: 100%;
      margin: 0 auto;
      color: $vision-body-color;
      font-size: 1.9vw;
      line-height: 1.3;
      font-weight: 800;

      @media only screen and (max-width: 990px) {
        font-size: 22px;
      }
    }
  }
}

// BXSLIDER CONTROLS - INDICATORS

$dot-size: 25px;

.bx-controls {
  display: block;
  margin: 30px 0 0 0;

  .bx-pager-item {
    @include box($dot-size);
    display: inline-block;
    margin: 0 5px;

    .bx-pager-link {
      @include box($dot-size);
      display: inline-block;
      overflow: hidden;
      color: transparent;
      font-size: 0;
      border-radius: 50%;
      background: #FFF;
    }

    .active {
      background: transparent;
      box-shadow: inset 0 0 0 2px #fff;
    }
  }
}

// // BXSLIDER CONTROLS - ARROWS 

// $arrow-size: 100px;

// .bx-prev,
// .bx-next {
//   @include box($arrow-size);
//   position: absolute;
//   color: transparent;
//   left: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   background: red;
// }

// .bx-next {
//   left: auto;
//   right: 0;
//   transform: translateY(-50%) scaleX(-1);
//   background: red;
// }