	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	.parallax {
		height: 30vw;
		border: 1.5vw solid #fff; 
		box-shadow: inset 0px 0px 250px 0px rgba(0, 0, 0, 0.4);
		min-height: 250px;

		@media only screen and (max-width: 990px) {
			height: 250px;
		}
	}

	footer {
		background: rgba($footer-bg, .9);
		clear: both;
		margin: 0 auto;
		padding: 80px 0 50px;
		position: relative;
		z-index: 99;
		overflow: hidden;

		@media only screen and (max-width: 1800px) {
			padding: 60px 0 40px 20%;
		}

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 40px 0;
		}

		.wrapper {
			text-align: left;
			font-size: 18px;
			color: #fff;
			line-height: 1.3;

			@media only screen and (max-width: 1500px) {
				padding: 0 0 0 20px;
			}

			@media only screen and (max-width: 990px) {
				width: 100%;
				padding: 0 5%;
				font-size: 16px;
				text-align: center;
			}
		}


		p.address {
			span {
				display: block;
				font-size: 130%;
				margin: 0 0 5px 0;
				font-weight: 800;
			}
		}

		strong {
			display: block;
		}

		a[href^="mailto:"] {
			display: inline-block;
			position: relative;
			color: #fff;

			@media only screen and (max-width: 990px) {
				display: block;
			}

			span {}

			&:before {
				@include before(0, 2px);
				border-radius: 10px;
				background: #fff;
				transition: .3s;
				left: 0;
				bottom: -5px;
				display: inline-block;
			}

			@media only screen and (min-width: 990px) {

				&:hover {
					&:before {
						width: 100%;
					}
				}
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}
	}

	.foot-btns {
		margin: 40px 0;

		a {
			display: inline-block;
			padding: 15px 50px;
			border-radius: 50px;
			box-shadow: inset 0 0 0 2px #fff;
			color: #fff;
			font-weight: 800;
			font-size: 16px;
			transition: .3s;

			@media only screen and (max-width: 990px) {
				margin: 5px;
			}

			&:hover {
				box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 30px rgba(#fff, .1);
				letter-spacing: 1px;
			}
		}
	}

	.trust-btn {
		@include center;
		left: -20%;
		right: auto;
		box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);

		@media only screen and (max-width: 1500px) {
			top: 40%;
			transform: scale(.6) translate(-50%, -50%);
		}

		@media only screen and (max-width: 990px) {
			@include posreset;
			display: block;
			width: 230px;
		}

		p {
			background: $trust-btn-bg;
			text-align: center;
			margin: 0;
			color: #fff;
			padding: 5px 0 8px;
			font-weight: 800;
			font-size: 14px;
			width: 100%;
			transition: .3s;

			img {
				display: inline-block;
				vertical-align: middle;
				margin: 0 0 0 5px;
				transition: .3s;
			}
		}

		&:hover {
			p {
				box-shadow: inset 250px 0 0 0 rgba(#fff, .1);

				img {
					transform: translateX(5px);
				}
			}
		}
	}

	#map {
		position: absolute;
		height: 100%;
		width: 45%;
		top: 0;
		right: 0;

		@media only screen and (max-width: 1800px) {
			width: 30%;
		}

		@media only screen and (max-width: 1500px) {
			width: 25%;
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	#copyright,
	#credit {
		display: inline-block;
		margin: 0;
		font-size: 13px;

		@media only screen and (max-width: 990px) {
			text-align: center;
			display: block;
			margin: 5px auto;
		}

		a {
			color: #fff;

			@media only screen and (max-width: 990px) {
				margin: 0;
				display: inline-block;
			}

			&:last-of-type {
				color: #fff;

				// GSW logo
				img {
					display: inline-block;
					vertical-align: middle;
					margin: -2px 0 0 5px;
				}
			}
		}
	}

	#copyright {
		margin: 0 30px 0 0;

		@media only screen and (max-width: 990px) {
			margin: 30px auto 5px;
			display: block;
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 80px;
		right: 50px;
		display: block;
		@include box(45px);
		background: $h1-color;
		box-shadow: 0 0 0 3px #fff, 0 0 0 8px rgba(#fff, .2);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}