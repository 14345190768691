// ----------------------------------- WELCOME SEC

#vision-sec {
 padding: 50px 0 140px;
 background: rgba($vision-bg, .9);
 position: relative;
 
 @media only screen and (max-width: 1800px) {
  padding: 50px 0 130px;
 }

 @media only screen and (max-width: 1500px) {
  padding: 50px 0 120px;
 }

 @media only screen and (max-width: 1350px) {
  padding: 50px 0 100px;
 }

 @media only screen and (max-width: 990px) {
  padding: 50px 0 80px;
 }

 &:before,
 &:after {
  @include before(80%, 100%);
  top: 0;
  right: 0;
  background: linear-gradient(to left, rgba($vision-bg, 1) 0%, rgba($vision-bg, 0) 100%);
 }

 .wrapper {
  width: 90%;
  z-index: 2;
 }

 .vision-wrap {
  width: 50%;
  display: inline-block;
  vertical-align: bottom;
  text-align: right;
  padding: 0 0 0 8%;
  color: $vision-body-color;

  @media only screen and (max-width: 990px) {
   width: auto;
   padding: 0;
   display: block;
   transform: none;
  }

  h2 {
   margin: 0 0 50px 0;
   font-size: 30px;
   color: $vision-title-color;
   line-height: 1.2;
   letter-spacing: 10px;
   font-weight: 500;

   @media only screen and (max-width: 1500px) {
    font-size: 25px;
   }

   @media only screen and (max-width: 990px) {
    letter-spacing: 0;
    font-size: 20px;
    margin: 0 auto 20px;
   }

   span {
    display: block;
    font-size: 50px;
    letter-spacing: 0;
    font-weight: 800;

    @media only screen and (max-width: 1500px) {
     font-size: 40px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 30px;
    }
   }
  }

  p {
   margin: 0 0 20px 0;

   &:first-of-type {
    font-size: 22px;

    @media only screen and (max-width: 1500px) {
     font-size: 20px;
    }

    @media only screen and (max-width: 990px) {
     font-size: 18px;
    }
   }
  }
 }
}

// -----------------------------------//
// EMPTY QUERS
// -----------------------------------//

// @media only screen and (max-width: 1800px) {}
// @media only screen and (max-width: 1500px) {}
// @media only screen and (max-width: 1350px) {}
// @media only screen and (max-width: 990px) {}
// @media only screen and (max-width: 500px) {}
// @media only screen and (max-width: 650px) and (orientation:landscape) {}