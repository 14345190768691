// --------------------- RESET MISC.

@import 'core/reset';
@import 'core/mixins';
@import 'core/accessibility';

// --------------------- STYLES
 
@import 'variables';

@import 'c_default';
@import 'c_slider';
@import 'c_header';
@import 'c_footer';
@import 'c_styles';
@import 'c_navholder';

// --------------------- OPTIONALS (move from /optionals/ to root to ENGAGE)

// @import 'loader';

@import 'quicklinks';
@import 'photo-buttons';

// @import 'social-wall';
// @import 'social-feed';

// @import 'bg-video';
// @import 'pop-up-vid';

@import 'bx-slider';

@import 'animated-stats';
// @import 'anstats-match';

// @import 'curriculum';

@import 'news-diary';
// @import 'pull-out-diary';

// @import 'swiper';

// --------------------- CMS UI Styles

@import 'core/respond';
@import 'core/ui_styles';

// --------------------- MENU

// @import 'megamenu';
@import 'core/meanmenu';
// @import 'meanmenu-ham'; // remember to hide alt.

@import 'core/insidepages-HAP';
@import 'core/cmsstyles';